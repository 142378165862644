body{
    background: #202020;
    color:white
}

.gallery-container {
    width: 100%; /* El contenedor ocupa todo el ancho disponible */
    max-width: 1000px; /* Máximo ancho del contenedor */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    padding: 0 40px; /* Añade un poco de espacio en los bordes */
    margin-left: 45px;
    
}

.gallery-container .image-gallery  .image-gallery-bullet {
    background-color: red; /* Cambia el color de los bullets a rojo */
    margin-bottom: -20px;
}

/* Media query para ajustar el tamaño del contenedor en pantallas más pequeñas */
@media screen and (max-width: 768px) {
    .gallery-container {
        max-width: 90%; /* Reducir el ancho máximo en pantallas más pequeñas */
    }


}